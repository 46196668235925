import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Grid from '@mui/material/Grid';
import Button from '../components/Button';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';

import graphicBook from '../images/book.svg';

const Library = () => {

  return (
    <Page title='Library'>
      {/* Hero */}
      <Section wide>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Journal' src={graphicBook}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Library</Typography>
            <Typography type='paragraph' preset={2}>Unlock happier self-care with our curated library exploring all things ‘mental health care’. </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section variant='wide'>
        <Grid container direction='row' spacing={6}>
          <Grid item xs={12} md={4}
            sx={{
              textAlign: 'left',
            }}
          >
            <StaticImage alt='Palm leaves' src='../images/image_cognitive.png'/>
          </Grid>
          <Grid item xs={12} md={8}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Cognitive Reappraisal: What Is Thought Reframing?</Typography>
            <Button theme='primary' branded href="/library/articles/cognitive-reappraisal">Read</Button>
          </Grid>


          <Grid item xs={12} md={4}
            sx={{
              textAlign: 'left',
            }}
          >
            <StaticImage alt='Minding your Mind - Prof. Ian Hickey and James O&amp;Loghlin' src='../images/image_minding.png'/>
          </Grid>
          <Grid item xs={12} md={8}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Burnout - Minding Your Mind Podcast</Typography>
            <Button theme='primary' branded href="/library/podcasts/burnout-minding-your-mind">Listen</Button>
          </Grid>


          <Grid item xs={12} md={4}
            sx={{
              textAlign: 'left',
            }}
          >
            <StaticImage alt='Sun shining through the trees' src='../images/image_emotions.png'/>
          </Grid>
          <Grid item xs={12} md={8}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Emotions, Feelings, and Moods: What's the difference?</Typography>
            <Button theme='primary' branded href="/library/articles/emotions-feelings-and-moods">Read</Button>
          </Grid>
        </Grid>
      </Section>


    </Page>
  )
};

export default Library;
